import {
  APP_INITIALIZER,
  ApplicationConfig,
  importProvidersFrom,
  inject,
  isDevMode,
} from '@angular/core';
import { provideRouter } from '@angular/router';

import { routes } from './app.routes';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@ngneat/transloco';
import { provideToastr } from 'ngx-toastr';
import { PeakShellModule } from 'peak-shell';
import { environment } from '../environments/environment';
import { AuthInterceptor } from './core/interceptors/auth.interceptor';
import { ConfirmationService } from 'primeng/api';
import { TranslateService } from './shared/services/translate.service';
import LanguageService from './shared/services/language.service';
import { RegionalService } from './shared/services/regional.service';
import { AvailableLanguage } from './shared/models/available-languages.model';
import { HotTableModule } from '@handsontable/angular';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    provideHttpClient(withInterceptorsFromDi()),
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideRouter(routes),
    provideAnimations(),
    provideToastr({
      timeOut: 5000,
      positionClass: 'toast-bottom-left',
      preventDuplicates: true,
    }),
    ConfirmationService,
    provideTransloco({
      config: {
        availableLangs: getAvailableLangs(),
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    importProvidersFrom(
      PeakShellModule.forRoot({
        auth: { baseUri: environment.PeakAuthUri, service: 'velocity' },
      }),
      HotTableModule
    ),
  ],
};

export function appInitializerFactory() {
  const languageService = inject(LanguageService);
  const regionalService = inject(RegionalService);
  return async () => {
    await languageService.init();
    const activeLanguage = languageService.getActiveLanguage();
    regionalService.registerCulture(activeLanguage);
  };
}

function getAvailableLangs(): string[] {
  const availableLanguages = environment.availableLangs.map(
    (language: AvailableLanguage) => language.IsoCode
  );
  return availableLanguages;
}
