import { Component, inject } from '@angular/core';
import { PeakInputTextModule } from 'peak-ui';
import { TranslatePipe } from '../../../../../core/pipes/translate.pipe';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { FooterSectionComponent } from '../../../../../shared/components/footer/footer.component';
import { FooterClickEvent } from '../../../../../shared/models/footer-event.enum';

@Component({
  selector: 'vlc-edit-location',
  standalone: true,
  imports: [PeakInputTextModule, FooterSectionComponent, TranslatePipe],
  templateUrl: './edit-location.component.html',
  styleUrl: './edit-location.component.scss',
})
export class EditLocationComponent {
  private ref = inject(DynamicDialogRef);
  submitForm(event: any) {}
  cancel(event: FooterClickEvent) {
    if (event == FooterClickEvent.Close) {
      this.ref.close();
    }
  }
}
