<div class="main-container">
  <pk-header
    *ngIf="permissionScope > 0"
    avatarSize="large"
    logo="/assets/img/logo.png"
    [avatarImage]="profile.ProfileImageUri || profileImageUri"
    [menuItems]="translatedMenu"
    avatarShape="square"
    [autoZIndex]="false"
    [autoDisplay]="false"
    [backgroundImageUrl]="'/assets/img/2006fd4d71056d0d3912c125b861e55c.png'"
    [optionLabel]="'name'"
    [filter]="true"
    [avatarMenu]="translatedAvatarMenu"
    [virtualScroll]="true"
    [virtualScrollItemSize]="38"
    [showEditableInput]="true"
    [selectedLanguage]="selectedLanguage"
    [avatarHeaderTemplate]="avatarHeaderTemplate"
    [avatarContentTemplate]="avatarContentTemplate"
    [isVisibleLeftButton]="true"
    [isVisibleMiddleButton]="true"
    [isVisibleRightButton]="true"
    [leftDisabledButton]="true"
    [middleDisabledButton]="true"
    [rightDisabledButton]="true"
    [logoRoute]="''"
    [menuType]="
      (isSmallScreen$ | async) || (isMediumScreen$ | async) ? 'slide' : 'tiered'
    "
  ></pk-header>

  <ng-template #avatarHeaderTemplate>
    <div
      class="avatar-header"
      [class.mobile]="(isSmallScreen$ | async) || (isMediumScreen$ | async)"
    >
      <div class="top-banner" *ngIf="this.profile">
        <div class="tag">
          <div
            class="user-role"
            *ngIf="profile.AuthId"
            [calculateUserRole]="permissionScope"
          ></div>
        </div>
      </div>
      <div class="content">
        <div class="avatar">
          <pk-avatar
            avatarSize="large"
            [avatarImage]="profile.ProfileImageUri || profileImageUri"
            [isOverlayDisabled]="true"
          ></pk-avatar>
        </div>
        <div class="info">
          <div class="name">
            {{ profile.FullName }}
          </div>
          <div class="mail">{{ profile.MailAddress }}</div>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template #avatarContentTemplate>
    <div class="avatarContent">
      <div class="location-content" *ngIf="endUser" (click)="editLocation()">
        <div class="icon-label-group">
          <i class="pi pi-map-marker icon"></i>
          <div class="label mr-2">Location</div>
        </div>
        <div class="edit-icon"><i class="pi pi-pencil"></i></div>
      </div>
      <div class="logout-content" (click)="logout()">
        <i class="pi pi-fw pi-power-off icon"></i>
        <div class="label mr-2">
          {{ "vlc:Admin:Logout" | translate }}
        </div>
      </div>
    </div>
  </ng-template>
  <peakShell
    [autoLogin]="true"
    [showHeader]="false"
    [showLoading]="true"
    [showLogo]="true"
    (onLogoutActions)="getLogoutAction($event)"
    (onResolvedProfile)="getResolvedProfile($event)"
  ></peakShell>
  <div class="content-container" *ngIf="permissionScope > 0">
    <div class="content-wrapper">
      <router-outlet></router-outlet>
    </div>
  </div>
  <vlc-loading></vlc-loading>
  <div *ngIf="profile.Id && permissionScope == 0">
    You do not have the required user role to view this app.
  </div>
</div>
<p-confirmDialog></p-confirmDialog>
